<template>
	<draggable v-bind="dragOptions" tag="div" class="item-container" :list="list" :value="value" @input="emitter">
		<div v-for="(item, index) in realValue" :key="item.id" class="item-group">
			<div class="item px-2 py-1 on-parent">
				<div class="row align-items-center">
					<div class="col d-flex">
						<strong v-if="value" class="text-primary-300 me-1 cursor-move">{{ index + 1 }}.</strong>
						<strong v-else class="text-gray me-1 cursor-move">{{ index + 1 }})</strong>

						<span>{{ item.title }}</span>

						<span v-if="item.status === 'needs_review'" class="badge ms-2 bg-primary-50 text-primary-400">
							{{ item.status.replace('_', ' ') }}
						</span>

						<span
							v-if="item.status === 'change_requested'"
							class="badge ms-2 bg-warning-50 text-warning-400"
						>
							{{ item.status.replace('_', ' ') }}
						</span>

						<span
							v-if="item.text"
							class="d-inline-block text-neutral-400 text-truncate ms-1"
							style="max-width: 300px"
						>
							- {{ item.text }}
						</span>
					</div>
					<div class="col-auto">
						<div class="dropdown show-on-hover">
							<button
								class="btn btn-sm btn-outline-secondary dropdown-toggle"
								type="button"
								id="dropdownMenuButton"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								Actions
							</button>
							<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
								<li>
									<button class="dropdown-item" @click="editItem(item)">
										<font-awesome-icon :icon="['fas', 'pencil']" /> Edit
									</button>
								</li>
								<li v-if="allowApprove && item.status === 'needs_review'">
									<button class="dropdown-item" @click="approveItem(item)">
										<font-awesome-icon :icon="['fas', 'check']" /> Approve
									</button>
								</li>
								<li>
									<button class="dropdown-item" @click="requestChanges(item)">
										<font-awesome-icon :icon="['fas', 'exclamation-circle']" /> Request Changes
									</button>
								</li>
								<li>
									<button class="dropdown-item" @click="moveToQueue">
										<font-awesome-icon :icon="['fas', 'exchange-alt']" /> Move to queue
									</button>
								</li>
								<li>
									<button class="dropdown-item" @click="removeItem(item)">
										<font-awesome-icon :icon="['fas', 'trash']" /> Delete
									</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div v-if="item.files && item.files.length" class="ps-3 mt-1">
					<a
						v-for="file in item.files"
						:key="file"
						:href="getPublicFileUrl(file)"
						target="_blank"
						class="inline-block px-2 py-1 border rounded-1 bg-neutral-50 text-dark me-2"
					>
						<font-awesome-icon
							:icon="['fas', file.endsWith('pdf') ? 'file-pdf' : 'file']"
							class="text-primary-100"
						/>
						{{ truncateString(file.split('/').at(-1)) }}</a
					>
				</div>
			</div>

			<agenda-items-list
				v-if="value"
				class="item-sub"
				:list="item.subitems"
				@removeItem="removeItem"
				@editItem="editItem"
				@requestChanges="requestChanges"
			/>
		</div>
	</draggable>
</template>

<style lang="scss" scoped>
.item-container {
	margin: 0;
}

.sortable-ghost {
	background-color: #fecaca;
	border-radius: 0.25rem;
}

.sortable-chosen {
	background-color: #fcd34d;
	border-radius: 0.25rem;
}

.sortable-drag {
	background-color: #67e8f9;
	border-radius: 0.25rem;
}

.item {
	border-radius: 0.25rem;
	transition: background-color 0.15s ease-in-out;

	&:hover {
		background-color: #f5f5f5;
	}
}

.item-sub {
	margin-left: 1.25rem;
}
</style>

<script>
import draggable from 'vuedraggable'

import { getPublicFileUrl } from '@/utils.js'
import { truncateString } from '@/lib/strings.js'

export default {
	name: 'AgendaItemsList',
	components: {
		draggable,
	},
	props: {
		value: {
			required: false,
			type: Array,
			default: null,
		},
		list: {
			required: false,
			type: Array,
			default: null,
		},
		allowApprove: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		dragOptions() {
			return {
				animation: 200,
				group: 'description',
				disabled: false,
			}
		},
		realValue() {
			return this.value || this.list
		},
	},
	methods: {
		getPublicFileUrl,
		truncateString,

		emitter(value) {
			this.$emit('input', value)
		},
		editItem(item) {
			this.$emit('editItem', item)
		},
		removeItem(item) {
			this.$emit('removeItem', item)
		},
		requestChanges(item) {
			this.$emit('requestChanges', item)
		},
		moveToQueue() {
			alert('not implemented')
		},
		approveItem(item) {
			this.$emit('approve', item)
		},
	},
}
</script>
