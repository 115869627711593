<template>
	<div class="meeting-overview">
		<div class="card mb-4">
			<div class="card-body">
				<div class="row">
					<div class="col-lg-6 mb-3">
						<p class="mb-2">
							<span class="text-neutral-400">Date: </span>
							<strong>{{
								new Date(meeting.starts_at).toLocaleString('en-US', {
									dateStyle: 'long',
									timeStyle: 'short',
									timeZone: j.timezone,
								})
							}}</strong>
						</p>
						<p class="mb-2">
							<span class="text-neutral-400">Location: </span>
							<small>-</small>
						</p>
						<p class="mb-0">
							<span class="text-neutral-400">Conferencing link: </span>
							<template v-if="meeting.conferencing_link">
								<a :href="meeting.conferencing_link" target="_blank">{{
									urlPart(meeting.conferencing_link)
								}}</a>
								<span v-if="meeting.conferencing_link_note" class="text-neutral-500 ms-1"
									>({{ meeting.conferencing_link_note }})</span
								>
							</template>
							<small v-else class="bg-neutral-300">-</small>
						</p>
					</div>
					<div class="col-lg-6 mb-3">
						<p class="mb-2">
							<span class="text-neutral-400 me-1">Department:</span>
							<template v-if="departments && meeting.department_id">{{
								departments.find(d => d.id == meeting.department_id).name
							}}</template>
							<small v-else class="text-neutral-300">(none)</small>
						</p>
						<p class="mb-0">
							<span class="text-neutral-400 me-1">
								{{ meeting.categories && meeting.categories.length === 1 ? 'Category' : 'Categories' }}:
							</span>
							<template v-if="meeting.categories && meeting.categories.length">
								{{
									meetingCategories
										.filter(c => meeting.categories.includes(c.id))
										.map(c => c.name)
										.join(', ')
								}}
							</template>
							<small v-else class="text-neutral-300">(none)</small>
						</p>
					</div>
				</div>

				<p class="card-text">
					<template v-if="meeting.description">{{ meeting.description }}</template>
					<small v-else class="text-neutral-300">(no meeting description)</small>
				</p>

				<hr class="my-3 bg-primary-100" />

				<p class="subtitle text-neutral-500 mb-2">Meeting workflow</p>

				<div class="bg-success-50 p-3 rounded-1 mb-2">
					<p class="lead mb-0"><strong class="text-success-400">✓</strong> Meeting is scheduled</p>
				</div>

				<router-link
					:to="`/${j.slug}/meetings/${meeting.pid}/agenda`"
					class="d-block p-3 rounded-1 mb-2"
					:class="meeting.agenda_items.length > 3 ? 'bg-success-50' : 'bg-neutral-50'"
				>
					<p v-if="meeting.agenda_items.length > 3" class="lead mb-0">
						<strong class="text-success-400">✓</strong> Agenda is ready
					</p>
					<template v-else>
						<p class="lead mb-0"><strong class="text-success-400">⚪️</strong> Add agenda</p>
						<p v-if="meeting.agenda_cutoff" class="mt- 1mb-0">
							Due by {{ meeting.starts_at | dateLocal }} - 7 days
						</p>
					</template>
				</router-link>

				<div
					v-if="!meeting.audio_file_path && !meeting.video_file_path"
					class="bg-neutral-50 p-3 rounded-1 mb-2"
				>
					<p class="lead mb-0"><strong class="text-success-400">⚪️</strong> Upload recording</p>
				</div>
				<div v-else class="bg-success-50 p-3 rounded-1 mb-2">
					<p class="lead mb-0"><strong class="text-success-400">✓</strong> Recording added</p>
				</div>

				<router-link
					:to="`/${j.slug}/meetings/${meeting.pid}/minutes`"
					class="d-block p-3 rounded-1 mb-2"
					:class="meeting.minutes_status === 'missing' ? 'bg-neutral-50' : 'bg-success-50'"
				>
					<span v-if="meeting.minutes_status === 'missing'" class="lead mb-0"
						><strong class="text-success-400">⚪️</strong> Create minutes</span
					>
					<p v-else class="lead mb-0"><strong class="text-success-400">✓</strong> Minutes ready</p>
				</router-link>
			</div>
		</div>

		<div v-if="isStaff" class="card">
			<div class="card-header bg-danger-lighter">
				<h5 class="my-0">Info for HeyGov staff</h5>
			</div>
			<div class="card-body">
				<p class="card-text">
					AI cost: <strong>{{ numberToCurrency(meeting.ai_cost) }}</strong>
				</p>
				<p class="card-text">
					Transcript:
					<a :href="`${apiUrl + j.slug}/meetings/${meeting.pid}/transcript-job-start`" target="_blank"
						>Run transcript job again</a
					>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { formatDistanceToNow } from 'date-fns'

import { hgApi } from '@/api.js'
import { getPublicFileUrl, numberToCurrency } from '@/utils.js'

export default {
	name: 'MeetingsOverview',
	components: {},
	metaInfo() {
		return {
			title: `${this.meeting?.title || this.$route.params.meetingId} - Meetings`,
		}
	},
	data() {
		return {
			meetingCategories: [],
		}
	},
	computed: {
		...mapState(['j', 'apiUrl', 'departments']),
		...mapGetters(['auth', 'currentRole', 'isStaff']),
	},
	props: {
		meeting: {
			type: Object,
			required: true,
		},
		meetingPlayer: {
			type: Object,
			required: true,
		},
	},
	created() {
		this.loadMeetingCategories()

		if (this.meeting.motions.length) {
			this.$store.dispatch('loadPeople', this.meeting.motions.map(m => [m.voted_by_id, m.seconded_by_id]).flat())
		}
	},
	methods: {
		getPublicFileUrl,
		formatDistanceToNow,
		numberToCurrency,

		loadMeetingCategories() {
			hgApi(`${this.j.slug}/meetings/categories`).then(response => {
				if (response.ok) {
					response.json().then(categories => {
						this.meetingCategories.push(...categories)
					})
				} else {
					Vue.toasted.error('Failed to load meeting categories')
				}
			})
		},

		urlPart(url) {
			return new URL(url).hostname
		},
	},
}
</script>
