<template>
	<div class="page-form-submission">
		<h3 class="mb-3">
			<router-link :to="`/${j.slug}/forms`">HeyLicense</router-link>
			<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-2" />
			<router-link :to="`/${j.slug}/forms/${$route.params.formSlug}`">{{
				form ? form.name : $route.params.formSlug
			}}</router-link>
			<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted ms-2" />
			{{
				formRequest && formRequest.application_number
					? formRequest.application_number
					: $route.params.formRequestId
			}}
		</h3>

		<div v-if="state === 'loaded'">
			<div class="row justify-content-center mb-4">
				<div class="col-md-10 col-lg-8">
					<div v-if="$route.query.sent" class="card mb-4">
						<div class="card-body text-center">
							<img
								src="https://edge.heygov.com/illustrations/woman-question.png"
								height="200"
								alt="Application sent"
							/>

							<div v-if="['sent', 'working'].includes(formRequest.status)">
								<p class="lead mb-4">
									{{
										form.endMessage ||
											`Your application has been sent at "${
												form.department_id
													? departments.find(d => d.id == form.department_id).name
													: j.name
											}" and is currently being processed.`
									}}
								</p>
								<p class="mb-0">
									<router-link :to="`/${j.slug}`" class="btn mx-2"
										>Back to {{ j.type }} overview</router-link
									>
									<a class="btn btn-primary mx-2" href="#application-info">
										View submission
									</a>
								</p>
							</div>
							<div v-else-if="formRequest.status === 'approved'">
								<p class="lead mb-4">
									{{
										formRequest.reviewed_message ||
											form.approvedMessage ||
											`Your ${form.type} is approved.`
									}}
								</p>
								<p class="mb-0">
									<router-link :to="`/${j.slug}`" class="btn mx-2"
										>Back to {{ j.type }} overview</router-link
									>
									<a
										target="_blank"
										class="btn btn-primary mx-2"
										:href="`${apiUrl + j.slug}/form-requests/${formRequest.pid}/pdf?appUrl=${url}`"
									>
										<font-awesome-icon :icon="['fas', 'file-pdf']" class="me-1" />
										<span class="text-capitalize">{{ form.type }}</span> PDF
									</a>
									<a
										v-if="form.has_certificate === 'pdf'"
										target="_blank"
										class="btn btn-primary mx-2"
										:href="
											formRequest.certificate_url ||
												`${apiUrl + j.slug}/forms/${form.slug}/license-template?frs=${
													formRequest.pid
												}&print=1`
										"
									>
										View certificate
									</a>
								</p>
							</div>
						</div>
					</div>

					<!-- Application info card -->
					<div id="application-status" class="card mb-4">
						<div class="card-header" :class="{ 'bg-danger-lighter': formRequest.status === 'declined' }">
							<h5 class="mb-0">
								<font-awesome-icon v-if="form.icon" :icon="['fas', form.icon]" /> {{ form.name }}
							</h5>
						</div>
						<div class="card-body">
							<div class="row mb-1">
								<div class="col-6">
									<p class="text-secondary mb-0">
										<span class="text-capitalize">{{ form.type }}</span> #
									</p>
									<p>
										<strong>{{ formRequest.application_number || formRequest.pid }}</strong>
									</p>
								</div>
								<div class="col-6">
									<p class="text-secondary mb-0">Sent on</p>
									<p>
										<strong>
											{{ formRequest.sent_at | dateTimeLocal }}
										</strong>
									</p>
								</div>
								<div class="col-6">
									<p class="text-secondary mb-0">Status</p>
									<p>
										<span class="badge" :class="[formRequestStatuses[formRequest.status].class]">{{
											formRequestStatuses[formRequest.status].name
										}}</span>
									</p>
								</div>
								<div v-if="formRequest.valid_until" class="col-6">
									<p class="text-secondary mb-0">Valid until</p>
									<p>
										<strong>
											{{ formRequest.valid_until | dateLocal }}
										</strong>
									</p>
								</div>

								<div v-if="['sent', 'working'].includes(formRequest.status)" class="col-6">
									<p class="text-secondary mb-0">PDF {{ form.type || 'application' }}</p>
									<p>
										<a
											target="_blank"
											:href="
												`${apiUrl + j.slug}/form-requests/${formRequest.pid}/pdf?appUrl=${url}`
											"
										>
											Download draft
										</a>
									</p>
								</div>
							</div>

							<div
								v-if="fieldType('PaymentElement') && !fieldType('PaymentElement').removed"
								class="row border-top pt-3 mb-3"
							>
								<div class="col-6">
									<p class="text-secondary mb-0">{{ fieldType('PaymentElement').label }}</p>
									<p>
										<strong>
											{{ fieldTypeAnswer('PaymentElement').price | currency }}
										</strong>
									</p>
								</div>
								<div class="col-6">
									<span
										v-if="
											fieldTypeAnswer('PaymentElement') &&
												['succeeded', 'canceled'].includes(
													fieldTypeAnswer('PaymentElement').status
												)
										"
									>
										<p class="mb-1">
											<payment-status
												:payment="fieldTypeAnswer('PaymentElement')"
												:tooltip="true"
											/>
										</p>
										<payment-field-summary
											:payment="fieldTypeAnswer('PaymentElement')"
										></payment-field-summary>
									</span>
									<span
										v-else-if="
											fieldTypeAnswer('PaymentElement') &&
												fieldTypeAnswer('PaymentElement').status === 'requires_capture'
										"
									>
										<p class="mb-1">
											<span class="badge bg-info-lighter text-info">On hold</span>
										</p>
										<payment-field-summary
											:payment="fieldTypeAnswer('PaymentElement')"
										></payment-field-summary>
									</span>
									<span v-else>
										<p class="mb-0">
											<span class="badge bg-warning-lighter text-warning">Waiting</span
											><span
												v-if="
													fieldType('PaymentElement').data.paymentType.includes('offline') ||
														fieldType('PaymentElement').data.paymentType.includes(
															'in-person'
														)
												"
											>
												for in person payment or
											</span>

											<span
												v-if="
													['sent', 'working'].includes(formRequest.status) &&
														fieldType('PaymentElement').data.paymentType.includes('card')
												"
											>
												<button class="btn btn-sm btn-outline-dark my-1" @click="tbd">
													Pay
													<strong v-if="fieldTypeAnswer('PaymentElement')">{{
														(fieldTypeAnswer('PaymentElement').amount / 100) | currency
													}}</strong>
													online
												</button></span
											>
										</p>
									</span>
								</div>
							</div>

							<div v-if="formRequest.status === 'approved'">
								<a
									target="_blank"
									class="btn btn-primary"
									:href="`${apiUrl + j.slug}/form-requests/${formRequest.pid}/pdf?appUrl=${url}`"
								>
									<font-awesome-icon :icon="['fas', 'file-pdf']" class="me-2" />
									<span class="text-capitalize">{{ form.type }}</span> PDF
								</a>

								<a
									v-if="form.has_certificate === 'pdf'"
									target="_blank"
									class="btn btn-primary mx-2"
									:href="
										formRequest.certificate_url ||
											`${apiUrl + j.slug}/forms/${form.slug}/license-template?frs=${
												formRequest.pid
											}&print=1`
									"
								>
									View certificate
								</a>
							</div>

							<div
								v-if="form.has_certificate === 'qrc' && formRequest.certificate_url"
								class="row justify-content-center mt-3"
							>
								<div class="col-6 col-lg-4">
									<img
										:src="formRequest.certificate_url"
										class="img-thumbnail"
										:alt="`${form.name} QR Code`"
									/>
								</div>
							</div>
							<div
								v-else-if="prescottQrCode.includes(`${j.slug}/${form.slug}`)"
								class="row justify-content-center mt-3"
							>
								<div class="col-6 col-lg-4">
									<img
										src="https://files.heygov.com/prescottwi.org/storage/mprescottcompost329.png"
										class="img-thumbnail"
										alt="Prescott QR Code"
									/>
								</div>
							</div>
						</div>
					</div>
					<!-- #END Application info card -->

					<!-- Needs attention card -->
					<div v-if="Object.keys(formRequest.required_changes).length" class="card mb-4">
						<div class="card-header">
							<h5 class="my-0">
								🙋 Requires attention
								<span class="badge bg-danger-lighter text-danger">{{
									Object.keys(formRequest.required_changes).length
								}}</span>
							</h5>
						</div>
						<div class="card-body">
							<p class="card-text mb-2">
								The staff at <strong>{{ j.name }}</strong> needs some additional information from you
								before they can continue reviewing your submission.
							</p>
							<p class="mb-2">
								Please scroll down and update the answer for:
							</p>
							<ul class="nicer-list mb-0">
								<li v-for="field in formFieldsWithChangeRequest" :key="`f-req-list-${field.id}`">
									<strong>{{ field.label }}</strong>
								</li>
							</ul>
						</div>
					</div>
					<!-- #END Need attention card -->

					<!-- Form application details -->
					<div v-if="formFields.length" id="application-info" class="card mb-4">
						<div class="card-header">
							<h5 class="mb-0">Submission details</h5>
						</div>
						<div class="card-body">
							<template v-for="step in form.steps">
								<div
									v-if="
										step.visible_to === 'EVERYONE' ||
											(step.visible_to === 'DEPARTMENT' &&
												formRequest.steps[step.id] &&
												formRequest.steps[step.id].status === 'approved')
									"
									:key="`fs-${step.id}`"
									class="form-step-answers border-bottom py-1 mb-3"
									:class="{ 'step-required': step.review_required }"
									:id="`form-step-${step.id}`"
								>
									<h3 class="text-neutral-500">{{ step.name }}</h3>
									<p v-if="step.description" class="mb-3">{{ step.description }}</p>

									<div
										v-for="field in step.fields.filter(f => elementIsVisible(f, formFields))"
										:key="`fsf-${field.id}`"
										:id="`form-field-${field.id}`"
										class="form-field mb-2"
										:class="`field-type-${field.type}`"
									>
										<div
											v-if="field.type === 'TextElement'"
											class="element"
											:class="field.class"
											v-html="fillTextWithVariables(field.value, formFields)"
										></div>

										<div
											v-else-if="field.type === 'PaymentElement'"
											class="row align-items-center rounded py-2 on-parent"
										>
											<div class="col">
												<p class="mb-2">
													{{ field.label }}

													<strong v-if="formRequest.answers[field.id]">{{
														formRequest.answers[field.id].price | currency
													}}</strong>
												</p>

												<div
													v-if="
														formRequest.answers[field.id] &&
															['succeeded', 'requires_capture'].includes(
																formRequest.answers[field.id].status
															)
													"
												>
													<p v-if="formRequest.answers[field.id].fee" class="my-2">
														Payment fee:
														{{ formRequest.answers[field.id].fee | currency }}
													</p>
													<p class="my-2">
														Paid
														<payment-field-summary
															:payment="formRequest.answers[field.id]"
														></payment-field-summary>
													</p>
												</div>
												<div
													v-else-if="
														formRequest.answers[field.id] &&
															formRequest.answers[field.id].status ===
																'requires_payment_method'
													"
												>
													Not paid yet
												</div>
												<p v-else class="my-1">
													{{ formRequest.answers[field.id] }}
												</p>
											</div>
										</div>
										<div
											v-else
											:class="{
												'border border-warning p-3 rounded-1':
													formRequest.required_changes[field.id],
											}"
										>
											<h6 class="mb-0">
												<small v-if="formRequest.required_changes[field.id]">⚠️</small>
												{{ field.label }}
											</h6>

											<div class="py-2">
												<file-upload-element
													v-if="field.type === 'FileUploadElement'"
													:currentField="{
														...field,
														value: formRequest.answers[field.id],
													}"
													:formRequest="formRequest"
													mode="preview"
												></file-upload-element>
												<signature-element
													v-else-if="field.type === 'SignatureElement'"
													:currentField="{
														...field,
														value: formRequest.answers[field.id],
													}"
													mode="preview"
												></signature-element>
												<table-element
													v-else-if="field.type === 'TableElement'"
													:currentField="{
														...field,
														value: formRequest.answers[field.id] || [],
														pdf: true,
													}"
												></table-element>
												<p v-else class="mb-0">
													{{ displayFieldAnswer(field, formRequest.answers[field.id]) }}
												</p>

												<div
													v-if="formRequest.required_changes[field.id]"
													class="bg-warning-50 rounded-1 py-2 px-3 mt-2"
												>
													<div class="row align-items-center">
														<div class="col">
															<font-awesome-icon
																:icon="['fas', 'comment']"
																class="text-secondary me-1"
															/>
															{{ formRequest.required_changes[field.id].message }}
														</div>
														<div class="col-auto">
															<button
																class="btn btn-sm btn-primary"
																data-bs-toggle="offcanvas"
																data-bs-target="#offcanvasChangeAnswer"
																aria-controls="offcanvasChangeAnswer"
																@click="fieldRequestChange(field)"
															>
																Update now
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</template>

							<div v-if="formRequest" class="bg-light rounded-1 p-3 my-4">
								<h5 class="mb-3">Application timeline</h5>
								<div class="form-request-timeline">
									<div class="form-request-timeline-box container">
										<div
											v-for="activity in formRequestActivity"
											:key="`fra-${activity.id}`"
											class="row border-bottom align-items-center"
											:class="[`activity-type-${activity.type}`, `hover-${activity.type}`]"
											:id="`form-request-activity-${activity.id}`"
										>
											<div class="col-auto">
												<small class="text-secondary">
													{{ activity.created_at | dateToNow }}</small
												>
											</div>
											<div class="col py-2 text-end">{{ activity.message }}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- #END Application details card -->
				</div>
			</div>
		</div>

		<div v-else-if="state === 'error'" class="py-4">
			<div class="alert alert-danger">Oops, we couldn't load this form application</div>
		</div>

		<div v-else class="text-center">
			<div class="spinner-border" role="status"></div>
		</div>

		<!-- Offcanvas for update answer -->
		<div
			class="offcanvas offcanvas-bottom"
			style="height: auto; max-height: 90%; overflow-y: auto"
			tabindex="-1"
			id="offcanvasChangeAnswer"
			data-bs-scroll="false"
			aria-labelledby="offcanvasChangeAnswerLabel"
		>
			<form @submit.prevent="submitAnswerChange">
				<div class="offcanvas-header">
					<h5 class="offcanvas-title" id="offcanvasChangeAnswerLabel">
						Change answer for "{{ requestedElement.label }}"
					</h5>
					<button
						type="button"
						class="btn-close text-reset"
						data-bs-dismiss="offcanvas"
						aria-label="Close"
					></button>
				</div>
				<div class="offcanvas-body">
					<div class="row justify-content-center">
						<div class="col-md-10 col-lg-8">
							<div class="bg-warning-50 px-3 py-2 rounded-1 mb-3">
								<font-awesome-icon :icon="['fas', 'comment']" class="text-secondary me-1" />
								{{ requestedChange.message }}
							</div>

							<div class="form-group mb-3">
								<label :for="`fsf-${requestedElement.id}`" class="form-label">{{
									requestedElement.label
								}}</label>

								<signature-element
									v-if="requestedElement.type === 'SignatureElement'"
									:currentField="requestedElement"
								></signature-element>
								<table-element
									v-else-if="requestedElement.type === 'TableElement'"
									:currentField="{
										...requestedElement,
										value: formRequest.answers[requestedElement.id] || [],
										pdf: false,
									}"
								></table-element>
								<component
									v-else
									:is="requestedElement.type"
									:currentField="requestedElement"
									:formRequest="formRequest"
									class="element"
									v-on:change="$emit('change', $event.target.value)"
								></component>
							</div>
						</div>
					</div>

					<div class="text-center">
						<button class="btn btn-primary">Update Answer</button>
					</div>
				</div>
			</form>
		</div>
		<!-- #END Offcanvas for updating answer -->
	</div>
</template>

<style lang="scss">
.require-change-badge .badge {
	color: white !important;
}

.card-form {
	overflow: hidden;
	min-height: 10rem;

	&.has-icon {
		background-color: rgba(224, 224, 224, 0.4);
	}

	.form-icon {
		right: 1.6rem !important;
		position: absolute;
		left: auto;
		width: 8rem;
		height: 8rem;
		color: #e0e0e0;
		opacity: 0.5;
		overflow: hidden;
	}
}

.field-type-TextElement img {
	max-width: 100% !important;
}

@media (max-width: 575.98px) {
	.card-form .form-icon {
		width: 7rem;
	}
}
</style>

<script>
import { mapState, mapGetters } from 'vuex'
import Vue from 'vue'

import heyGovApi, { handleResponseError } from '@/api.js'
import { elementIsVisible, fillTextWithVariables, formRequestStatuses } from '@/actions/forms.js'
import { displayFieldAnswer } from '@/actions/form-builder.js'
import { urlIsImage, urlIsDocument, urlIsVideo } from '@/utils.js'

import PersonAvatar from '@/components/PersonAvatar.vue'
import PaymentFieldSummary from '../../components/forms/PaymentFieldSummary.vue'
import PaymentStatus from '../../components/payments/PaymentStatus.vue'

import TextInputElement from '@/components/form-builder/TextInputElement'
import TextareaInputElement from '@/components/form-builder/TextareaInputElement'
import NumberInputElement from '@/components/form-builder/NumberInputElement'
import SelectListElement from '@/components/form-builder/SelectListElement'
import RadioButtonElement from '@/components/form-builder/RadioButtonElement'
import CheckboxElement from '@/components/form-builder/CheckboxElement'
import DatePickerElement from '@/components/form-builder/DatePickerElement'
import TimeElement from '@/components/form-builder/TimeElement'
import FileUploadElement from '@/components/form-builder/FileUploadElement'
import EmailInputElement from '@/components/form-builder/EmailInputElement'
import PhoneInputElement from '@/components/form-builder/PhoneInputElement'
import TableElement from '@/components/form-builder/TableElement'
import YearElement from '@/components/form-builder/YearElement'
import SignatureElement from '@/components/form-builder/SignatureElement'
import PaymentElement from '@/components/form-builder/PaymentElement'
import AddressInputElement from '@/components/form-builder/AddressInputElement'
import NameInputElement from '@/components/form-builder/NameInputElement'

export default {
	name: 'FormRequestInfo',
	metaInfo() {
		return {
			title: `${this.formRequest?.application_number || this.$route.params.formRequestId} form submission`,
		}
	},
	components: {
		PersonAvatar,
		PaymentFieldSummary,
		PaymentStatus,
		TextInputElement,
		TextareaInputElement,
		NumberInputElement,
		SelectListElement,
		RadioButtonElement,
		CheckboxElement,
		DatePickerElement,
		TimeElement,
		FileUploadElement,
		EmailInputElement,
		PhoneInputElement,
		TableElement,
		YearElement,
		SignatureElement,
		PaymentElement,
		AddressInputElement,
		NameInputElement,
	},
	data() {
		return {
			formRequestStatuses,
			state: 'loading',

			form: null,
			formRequest: null,
			formRequestActivity: [],

			// field change requests
			requestedChange: {},
			requestedElement: {},
			requestOldValue: null,

			prescottQrCode: [
				'heyville.org/boat-launch',
				'prescottwi.org/compost-permit',
				'prescottwi.org/non-resident-compost-permit',
			],

			url: window.location.protocol + '//' + window.location.hostname,
		}
	},
	computed: {
		...mapState(['j', 'apiUrl', 'departments']),
		...mapGetters(['auth', 'isStaff']),
		formFields() {
			let fields = []

			if (this.form?.steps) {
				fields = this.form.steps
					.map(step => step.fields)
					.flat()
					.map(field => {
						if (
							!['TextElement', 'SignatureElement'].includes(field.type) &&
							this.formRequest?.answers[field.id]
						) {
							field.value = this.formRequest.answers[field.id]
						}

						return field
					})
			}

			return fields
		},
		formFieldsWithValues() {
			return this.formFields.map(field => {
				return {
					...field,
					value: this.formRequest.answers[field.id],
				}
			})
		},
		formFieldsWithChangeRequest() {
			const ids = Object.keys(this.formRequest.required_changes).map(id => parseInt(id, 10))

			return this.formFields.filter(field => ids.includes(field.id))
		},
	},
	created() {
		if (this.auth) {
			this.loadFormAndRequest()
			this.$store.dispatch('loadDepartments')
		} else {
			this.$router.push({
				name: 'Login',
				query: {
					redirect: window.location.pathname,
					message: 'Need to login before accessing the form application',
				},
			})
		}
	},
	methods: {
		fillTextWithVariables,
		displayFieldAnswer,
		urlIsImage,
		urlIsDocument,
		urlIsVideo,
		elementIsVisible,

		tbd() {
			alert('We are still working on this feature')
		},
		loadFormAndRequest() {
			this.state = 'loading'

			// Load form and formRequest together, to make page load faster
			Promise.all([
				heyGovApi.get(`/${this.j.slug}/forms/${this.$route.params.formSlug}?expand=steps`),
				heyGovApi.get(`/${this.j.slug}/form-requests/${this.$route.params.formRequestId}`),
			])
				.then(([form, formRequest]) => {
					if (form.data.id !== formRequest.data.form_id) {
						throw new Error('Form and formRequest do not match')
					}

					form.data.steps = form.data.steps.map(step => {
						step.fields = step.fields.map(f => {
							//todo remove this when api returns fields data/options in JSON format
							if (!f.data || typeof f.data === 'string') {
								f.data = JSON.parse(f.data || '{}')
							}
							if (f.options && typeof f.options === 'string') {
								f.options = JSON.parse(f.options)
							}

							if (f.type === 'CheckboxElement' || f.type === 'FileUploadElement') {
								f.value = JSON.parse(f.value)
							}

							return f
						})

						return step
					})

					this.form = form.data

					this.formRequest = formRequest.data
					this.$store.dispatch('loadPerson', formRequest.data.person_id)

					this.state = 'loaded'
				})
				.catch(error => {
					this.state = 'error'
					Vue.toasted.error(`Error loading form application (${error.message})`)
				})

			// load form request activity
			heyGovApi
				.get(`/${this.j.slug}/form-requests/${this.$route.params.formRequestId}/activity`)
				.then(({ data }) => {
					if (data.length) {
						this.formRequestActivity.push(...data)
					}
				})
		},

		addActivityMessage(message) {
			return heyGovApi
				.post(`${this.j.slug}/form-requests/${this.formRequest.id}/activity`, message)
				.then(({ data }) => {
					this.formRequestActivity.push(data)
				})
		},

		// TODO move some of these functions to actions/forms.js
		fieldType(fieldType) {
			return this.formFields.find(f => f.type === fieldType)
		},
		fieldTypeAnswer(fieldType) {
			const field = this.fieldType(fieldType)

			return field && this.formRequest.answers[field.id] ? this.formRequest.answers[field.id] : null
		},

		fieldRequestChange(field) {
			this.requestedChange = this.formRequest.required_changes[field.id]
			this.requestedElement = this.formFields.find(f => f.id == field.id)
			this.requestedElement.value = this.formRequest.answers[field.id]
			this.requestOldValue = window.structuredClone(this.formRequest.answers[field.id])
		},

		// Submit answer changes
		submitAnswerChange() {
			this.$set(this.formRequest.answers, this.requestedElement.id, this.requestedElement.value)
			let updateMessage = `${this.auth.name} updated the answer for "${this.requestedElement.label}"`

			const oldValue = displayFieldAnswer(this.requestedElement, this.requestOldValue, true)
			const newValue = displayFieldAnswer(this.requestedElement, this.requestedElement.value, true)

			if (typeof oldValue === 'string' && typeof newValue === 'string') {
				updateMessage = `${this.auth.name} updated "${this.requestedElement.label}" from "${oldValue}" to "${newValue}"`
			}

			delete this.formRequest.required_changes[this.requestedElement.id]
			heyGovApi
				.put(`${this.j.slug}/form-requests/${this.formRequest.id}`, {
					answers: this.formRequest.answers,
					required_changes: this.formRequest.required_changes,
				})
				.then(() => {
					this.addActivityMessage({
						message: updateMessage,
					})
					Vue.toasted.success(`Updated answer for "${this.requestedElement.label}" is submitted`)
				}, handleResponseError('Error updating answer ({error})'))

			document.querySelector('#offcanvasChangeAnswer .btn-close').click()
		},

		signatureUndo() {
			this.$refs.signaturePad.undoSignature()
		},
		signatureClear() {
			this.$refs.signaturePad.clearSignature()
		},
	},
}
</script>
